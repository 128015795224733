.list {
	margin: 0;
	padding: 0;
	display: grid;
	grid-template-columns: 1fr;
	grid-template-rows: repeat(6, 200px);
	list-style-type: none;
	gap: 20px;
}

.item {
	border-radius: 14px;
	background: var(--vkws_landing--card--bg);
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: flex-start;
	padding: 18px 18px 18px 12px;
	transition: transform var(--vkws_landings--transition--duration) var(--vkws_landings--transition--timing-function);
}

.item:active {
	transform: scale(0.96);
}

.icon {
	flex-shrink: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 46px;
	height: 46px;
	border-radius: 10px;
	background: var(--vkws_landing--services-card-icon--bg);
}

.img {
	width: 24px;
	height: 24px;
}

.head {
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
	gap: 17px;
	margin-bottom: 10px;
}

.body {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	color: var(--vkws_landings--color--desc-grey);
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 140%;
	height: 100%;
}

@media screen and (min-width: 1024px) {
	.list {
		grid-template-columns: 1fr 1fr;
		grid-template-rows: repeat(3, 157px);
		gap: 11px 8px;
	}

	.item {
		padding: 26px 26px 26px 19px;
		transition: none;
	}

	.item:active {
		transform: none;
	}

	.icon {
		width: 43px;
		height: 43px;
		border-radius: 11px;
	}

	.img {
		width: 20px;
		height: 20px;
	}

	.head {
		gap: 12px;
		margin-bottom: 0;
	}

	.body {
		font-size: 13px;
	}
}

@media screen and (min-width: 1440px) {
	.list {
		gap: 11px;
		grid-template-rows: repeat(3, 220px);
	}

	.item {
		border-radius: 20px;
		padding: 36px 36px 36px 26px;
	}

	.icon {
		width: 60px;
		height: 60px;
		border-radius: 16px;
	}

	.img {
		width: 28px;
		height: 28px;
	}

	.head {
		gap: 16px;
	}

	.body {
		font-size: 18px;
	}
}
