.root-bg {
	background-color: var(--vkws_landings--color--black);
	position: relative;
}

.section {
	display: grid;
	grid-template-columns: 1fr;
	align-items: flex-start;
	padding-top: 0;
	padding-bottom: 50px;
	position: relative;
	gap: 32px;
}

.main-title {
	margin: 0;
	padding-bottom: 0;
	color: var(--vkws_landing--variant-card-title--color);
	font-family: var(--vkws_landings--default-font--family);
	font-size: 24px;
	font-style: normal;
	font-weight: 500;
	line-height: 120%;
}

.cards {
	display: grid;
	grid-template-rows: repeat(6, 264px);
	grid-template-columns: 1fr;
	gap: 20px;
}

.preview {
	grid-template-rows: repeat(3, 264px);
}

.card {
	overflow: hidden;
	background-color: var(--vkws_landings--color--raisin-black);
	width: 100%;
	height: auto;
	border-radius: 17px;
	padding: 24px 20px;
	position: relative;
	display: flex;
	flex-direction: column;
}

.desc {
	margin-top: 16px;
	font-size: 14px;
	color: var(--vkws_landing--cases-desc--color);
	font-style: normal;
	font-weight: 400;
	line-height: 140%;
}

.text-logo {
	width: 17px;
	height: 17px;
	margin-left: 3px;
}

.button {
	display: flex;
	width: 100%;
	height: 50px;
	padding: 0;
	justify-content: center;
	align-items: center;
	border-radius: 6px;
	font-size: 16px;
	font-weight: 500;
}

.more-stories {
	width: 100%;
}

.logo-wrapper {
	width: 142px;
	height: 21px;
}

.actions {
	margin-top: auto;
}

.link {
	color: var(--vkws_landings--color--white);
}

.link:hover {
	color: var(--vkws_landing--cases-link--color--hover);
}

.link:hover svg,
.link:hover path{
	stroke: var(--vkws_landing--cases-link--color--hover);
}

.text {
	padding: 0;
	margin: 0;
	display: flex;
	align-items: center;
	font-size: 16px;
}

@media screen and (min-width: 1024px) {
	.section {
		padding-top: 0;
		padding-bottom: 100px;
		width: 884px;
		gap: 40px;
	}

	.main-title {
		padding: 0;
		margin: 0;
		font-size: 36px;
		line-height: 120%;
	}

	.cards {
		grid-template-rows: repeat(2, 260px);
		grid-template-columns: repeat(3, 1fr);
		gap: 7px;
	}

	.preview {
		grid-template-rows: 260px;
	}

	.card {
		padding: 28px 28px;
	}

	.desc {
		margin-top: 14px;
		font-size: 15px;
	}

	.button {
		width: 147px;
		height: 40px;
	}

	.more-stories {
		width: 177px;
	}

	.logo-wrapper {
		width: 142px;
		height: 21px;
	}

	.text {
		font-size: 16px;
	}
}

@media screen and (min-width: 1440px) {
	.section {
		padding-top: 0;
		padding-bottom: 80px;
		width: 1240px;
		gap: 60px;
	}

	.main-title {
		font-size: 54px;
	}

	.cards {
		grid-template-rows: repeat(2, 380px);
		grid-template-columns: repeat(3, 1fr);
		gap: 9px;
	}

	.preview {
		grid-template-rows: 380px;
	}

	.card {
		border-radius: 24px;
		padding: 40px 40px;
	}

	.desc {
		margin-top: 20px;
		font-size: 22px;
	}

	.text-logo {
		width: 24px;
		height: 24px;
		margin-left: 4px;
	}

	.button {
		border: 12px;
		width: 212px;
		height: 66px;
		font-size: 24px;
		font-style: normal;
		font-weight: 500;
		line-height: 150%;
	}

	.more-stories {
		width: 257px;
	}

	.logo-wrapper {
		width: 198px;
		height: 30px;
	}

	.text {
		font-size: 20px;
	}
}
