.css-local-variables {

	--vkws_landing--promo-title--color: #F1F1F1;
	--vkws_landing--promo-desc--color: #B3B3B4;
	--vkws_landing--card--bg: #1D1D1F;
	--vkws_landing--slider-skip-button-color: #666670;
	--vkws_landing--slider-skip-button-color--hover: var(--vkws_landing--promo-title--color);
	--vkws_landing--platform-logo--border-color: #353639;
	--vkws_landing--platform-logo--border-color--active: var(--vkws_landings--color--turquoise);
	--vkws_landing--cases-desc--color: #7F8A96;
	--vkws_landing--cases-link--color--hover: var(--vkws_landings--color--turquoise);
	--vkws_landing--checkbox-background-color-idle: #111111;
	--vkws_landing--checkbox-border-color-idle: rgba(249, 249, 249, 0.8);
	--vkws_landing--checkbox-border-radius-checkbox: 4px;
	--vkws_landing--checkbox-figure-color-checked: #111111;
	--vkws_landing--dialog--backdrop-bg: rgba(169, 169, 169, 0.15);
	--vkws_landing--dialog--bg: var(--vkws_landings--color--dark-grey);
	--vkws_landing--dialog--close-icon--color: #ffffff;
	--vkws_landing--dialog--description--color: #ffffff;
	--vkws_landing--dialog--title--color: #ffffff;
	--vkws_landing--faq_question--border-color: transparent;
	--vkws_landing--faq_quesion--color: var(--vkws_landings--color--white);
	--vkws_landing--input--bg: rgba(237, 240, 243, 0.2);
	--vkws_landing--input--bg_disabled: rgba(255, 255, 255, 0.2);
	--vkws_landing--input--bg_focus: rgba(237, 240, 243, 0.2);
	--vkws_landing--input--bg_hover: rgba(237, 240, 243, 0.3);
	--vkws_landing--input--border: 1px solid #5C5C5C;
	--vkws_landing--input--border_focus: 1px solid #5C5C5C;
	--vkws_landing--input--border_hover: 1px solid #5C5C5C;
	--vkws_landing--input--required-star--color: #01EAFF;
	--vkws_landing--input--text-color: #FFF4F4;
	--vkws_landing--textarea--bg: rgba(237, 240, 243, 0.2);
	--vkws_landing--textarea--bg_focus: rgba(237, 240, 243, 0.2);
	--vkws_landing--textarea--bg_hover: rgba(237, 240, 243, 0.3);
	--vkws_landing--textarea--border: 1px solid #5C5C5C;
	--vkws_landing--textarea--border_focus: 1px solid #5C5C5C;
	--vkws_landing--textarea--border_hover: 1px solid #5C5C5C;
	--vkws_landing--textarea--text-color: #FFF4F4;
	--vkws_landings--bg_body: var(--vkws_landings--color--black);
	--vkws_landing--variant-card--bg: #1D1D1F;
	--vkws_landing--variant-card-title--color: #F1F1F1;
	--vkws_landing--variant-card-desc--color: var(--vkws_landings--color--variant-card-desc--color);
	--vkws_landing--variant-card-button--bg-hover: #cccccc;
	--vkws_landings--desktop-slider-menu-background-dark-active--border: #373737;
	--vkws_landing--services-card-icon--bg: #181818;
}

.css-local-variables {
	--vkws_landings--button--font_size: 15px;
	--vkws_landings--button--border_radius: 6px;

	--vkws_landings--swiper--z-index: 1;
	--vkws_landings--swiper-section--z-index: calc(1 + var(--vkws_landings--swiper--z-index));
	--vkws_landings--header--z-index: calc(1 + var(--vkws_landings--swiper-section--z-index));

	--vkws_landing--solutions-card--bg: var(--vkws_landings--color--black);
	scroll-behavior: smooth;
}

@media screen and (min-width: 1024px) {
	.css-local-variables {
		--vkws_landings--button--font_size: 16px;
		--vkws_landings--button--height: 40px;
	}

	.css-local-variables::-webkit-scrollbar {
		display: none;
		-webkit-appearance: none;
		width: 0;
		height: 0;
	}
}

@media screen and (min-width: 1440px) {
	.css-local-variables {
		--vkws_landings--button--font_size: 22px;
		--vkws_landings--button--height: 58px;
		--vkws_landings--button--border_radius: 10px;
	}
}
