.root-bg {
	background-color: var(--vkws_landing--card--bg);
}

.root {
	padding-top: 50px;
	padding-bottom: 50px;
}

.title {
	margin: 0;
	font-size: 26px;
	line-height: 30px;
}

.button {
	max-width: 100%;
	font-size: 16px;
	line-height: 20px;
	height: 50px;
}

.control {
	padding-left: 15px;
}

.control::placeholder {
	color: var(--vkws_landings--color--white);
	opacity: 0.7;
}

.form-wrapper {
	margin-top: 10px;
	padding: 0 10px;
}

@media screen and (min-width: 1024px) {
	.root {
		padding-top: 86px;
		justify-content: space-between;
		flex-wrap: wrap;
	}

	.title {
		margin-top: 82px;
		font-size: 36px;
		line-height: 43px;
	}

	.content {
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		width: 307px;
	}

	.button-wrapper {
		margin-top: 0;
	}

	.button {
		height: 40px;
		font-size: 16px;
		line-height: 28px;
	}

	.form-wrapper {
		margin-top: 0;
		width: 486px;
		padding-right: 14px;
	}

	.form {
		display: flex;
		flex-direction: column;
		gap: 20px;
	}

	.contacts {
		margin: 0;
	}

	.control {
		margin-top: 0;
		margin-bottom: 0;
		font-size: 16px;
		line-height: 28px;
	}
}

@media screen and (min-width: 1440px) {
	.root {
		padding-top: 127px;
		padding-bottom: 124px;
	}

	.form-wrapper {
		width: 589px;
		padding-right: 9px;
	}

	.content {
		width: 470px;
	}

	.title {
		margin-top: 56px;
		font-size: 56px;
		line-height: 67px;
	}

	.form {
		gap: 31px;
	}

	.control {
		padding-left: 19px;
		font-size: 18px;
		line-height: 28px;
	}

	.button {
		height: 58px;
		font-size: 22px;
		line-height: 28px;
	}
}
