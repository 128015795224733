.root {
	margin: 0;
	padding: 0;
	list-style-type: none;
}

.answerHeight {
	max-height: 100px;
}

@media screen and (min-width: 1440px) {
	.answerHeight {
		max-height: 150px;
	}
}
