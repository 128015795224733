@media screen and (min-width: 1024px) {
	.root {
		display: flex;
		flex-direction: row;
		align-items: stretch;
		justify-content: space-between;
		gap: 38px;
	}

	.content {
		width: 587px;
		height: 436px;
		overflow: hidden;
		padding: 0;
		border-radius: 14px;
		background: var(--vkws_landing--desktop-slider-content-background--color);
	}

	.menu {
		margin: 0;
		padding: 0;
		display: flex;
		flex-direction: column;
		align-items: stretch;
		justify-content: space-between;
	}

	.circles {
		margin-left: 15px;
		margin-bottom: 26px;
		width: 39px;
		height: 10px;
	}
}

@media screen and (min-width: 1440px) {
	.root {
		gap: 53px;
	}

	.content {
		width: 823px;
		height: 612px;
		border-radius: 20px;
	}

	.circles {
		margin-left: 19px;
		margin-bottom: 37px;
		width: 55px;
		height: 14px;
	}
}
