.list {
	padding: 0;
	margin: 0;
	display: flex;
	flex-direction: column;
	align-items: stretch;
	justify-content: flex-start;
	gap: 20px;
}

.item {
	min-height: 264px;
	background-color: var(--vkws_landing--desktop-slider-background--color);
	border-radius: 24px;
	padding: 36px 24px 20px;
	display: flex;
	flex-direction: column;
	align-items: stretch;
	justify-content: flex-start;
	gap: 10px;
	color: var(--vkws_landing--desktop-slider-description--color);
}

.head {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.img-wrapper {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 48px;
	height: 48px;
	border-radius: 13px;
	background-color: var(--vkws_landing--desktop-slider-icon--color);
}

.title {
	color: var(--vkws_landing--title--color);
	font-size: 20px;
	font-style: normal;
	font-weight: 500;
	line-height: 140%;
}

.desc {
	color: var(--vkws_landing--slider-item-desc);
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 140%;
}
