@media screen and (min-width: 1024px) {
	.root {
		display: flex;
		flex-direction: row;
		align-items: center;
		background-color: inherit;
		color: var(--vkws_landing--desktop-slider-menu-text--color);
		cursor: pointer;
		height: 57px;
		padding: 11.4px;
		font-size: 14px;
		gap: 14px;
		border-radius: 17px;
		font-style: normal;
		font-weight: 500;
		line-height: 140%;
		width: 257px;
	}

	.img-wrapper {
		color: var(--vkws_landing--desktop-slider-menu-icon--color);
		background: var(--vkws_landing--desktop-slider-menu-background--color);
		border-radius: 9px;
		width: 34px;
		height: 34px;
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.root-active {
		background-color: var(--vkws_landing--desktop-slider-menu-background-active--color);
		color: var(--vkws_landing--title--color);
		box-shadow: 9px 9px 17px 0 rgba(0, 0, 0, 0.1), 0 3px 7px 0 rgba(255, 255, 255, 0.02) inset, 0 1px 1px 0 rgba(255, 255, 255, 0.15) inset;
		border: 1px solid var(--vkws_landing--desktop-slider-menu-background-active--border);
	}

	.root-active > .img-wrapper-blue {
		background-color: var(--vkws_landing--desktop-slider-icon--color);
	}

	.root-active > .img-wrapper-green {
		background-color: var(--vkws_landing--desktop-slider-green-icon--color);
	}

	.root-active > .img-wrapper-green svg {
		color: var(--vkws_landing--section-background--color);
	}

	.img-wrapper svg {
		width: 16px;
		height: 16px;
		color: var(--vkws_landing--title--color);
	}
}

@media screen and (min-width: 1440px) {
	.root {
		height: 80px;
		padding: 16px;
		font-size: 20px;
		gap: 20px;
		border-radius: 24px;
		font-style: normal;
		font-weight: 500;
		line-height: 140%;
		width: 361px;
	}

	.root-active {
		box-shadow: 12px 12px 24px 0 rgba(0, 0, 0, 0.1),
		0 4px 10px 0 rgba(255, 255, 255, 0.02) inset,
		0 1px 1px 0 rgba(255, 255, 255, 0.15) inset;
		border: 2px solid var(--vkws_landing--desktop-slider-menu-background-active--border);
	}

	.img-wrapper {
		border-radius: 12.8px;
		width: 48px;
		height: 48px;
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.img-wrapper svg {
		width: 22.4px;
		height: 22.4px;
	}
}
