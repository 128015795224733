.form {
	display: flex;
	flex-direction: column;
	gap: 20px;
}

.contacts {
	margin-top: 32px;
}

.control {
	width: 100%;
	resize: none;
}

.email {
	margin-top: 20px
}

.messages {
	margin-top: 12px;
}

.button-wrapper {
	display: flex;
	justify-content: center;
}

.button {
	flex-grow: 1;
}

.goals {
	order: 8;
}

@media screen and (min-width: 1024px) {
	.button-wrapper {
		margin-top: 12px;
		display: block;
	}

	.contacts {
		display: grid;
		grid-template-columns: 1fr 1fr;
		grid-gap: 20px;
	}

	.email {
		margin-top: 0;
	}

	.goals {
		order: unset;
	}

	.button {
		max-width: var(--vkws_landings--button--max-width);
	}
}
