.root {
	padding-top: 60px;
	display: grid;
	grid-template-columns: 1fr;
}

.grid {
	display: grid;
	gap: 8px;
}

.title {
	font-size: 24px;
	line-height: 130%;
	margin: 0;
}

.card {
	border-radius: 10px;
	padding: 20px;
	background: var(--vkws_landing--card--bg);
	overflow: hidden;
}

.cardTitle {
	font-weight: 500;
	font-size: 17px;
	margin: 0;
}

.bigCard {
	display: flex;
	flex-direction: column;
}

.imageWrapper {
	margin: 10px auto;
}

.cardWithIcon {
	align-items: center;
	display: grid;
	gap: 20px;
	grid-template-columns: min-content auto;
	grid-template-rows: auto;
}

.iconWrapper {
	background: var(--vkws_landing--checkbox-background-color-idle);
	width: 48px;
	height: 48px;
	border-radius: 16px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.icon {
	width: 28px;
	height: 28px;
}

.cardText {
	font-size: 15px;
	line-height: 21px;
	margin: 0;
}

.cardWithText {
	order: -1;
}

@media screen and (min-width: 1024px) {
	.root {
		padding-top: 58px;
	}

	.grid {
		grid-template-rows: 120px auto;
		grid-template-columns: 345px repeat(3, 1fr);
		grid-template-areas: 'a b b b' 'a c d e'
	}

	.title {
		line-height: 110%;
	}

	.card {
		border-radius: 20px;
	}

	.bigCard {
		padding: 30px;
		position: relative;
		height: 100%;
		justify-content: space-between;
		align-items: flex-start;
		grid-area: a;
	}

	.imageWrapper {
		position: absolute;
		margin: 0;
		bottom: 0;
		right: -90px;
	}

	.cardTitle {
		line-height: 20px;
		font-size: 15px;
	}

	.cardWithText {
		padding: 40px 40px 40px 20px;
		order: inherit;
		grid-area: b;
		display: flex;
		align-items: center;
	}

	.cardWithIcon:first-child {
		grid-area: c;
	}

	.cardWithIcon:nth-child(2) {
		grid-area: d;
	}

	.cardWithIcon:last-child {
		grid-area: e;
	}

	.cardWithIcon {
		grid-template-columns: auto;
		grid-template-rows: min-content max-content;
	}

	.cardText {
		font-size: 13px;
		line-height: 18px;
	}

	.iconWrapper {
		width: 46px;
		height: 46px;
		border-radius: 14px;
	}

	.icon {
		width: 21px;
		height: 21px;
	}
}

@media screen and (min-width: 1440px) {
	.root {
		padding-top: 80px;
	}

	.grid {
		grid-template-rows: 202px 216px;
		grid-template-columns: 595px repeat(3, 1fr);
	}

	.title {
		font-size: 36px;
		font-weight: 500;
	}

	.bigCard {
		padding: 36px;
	}

	.imageWrapper {
		right: -90px;
	}

	.cardTitle {
		line-height: 28px;
		font-size: 22px;
	}

	.cardWithText {
		padding: 57px 143px 57px 20px;
	}

	.cardText {
		font-size: 17px;
		line-height: 24px;
	}

	.iconWrapper {
		width: 60px;
		height: 60px;
		border-radius: 18px;
	}

	.icon {
		width: 27px;
		height: 27px;
	}
}
