.root {
	flex-direction: column;
	padding-top: 59px;
	padding-bottom: 116px;
}

.question {
	font-size: 18px;
	line-height: 1.25em;
}

.answer {
	font-size: 12px;
	line-height: 1.3em;
}

.link {
	text-decoration: underline;
	color: var(--vkws_landings--color--main_blue);
}

.ref {
	text-decoration: underline;
	color: var(--vkws_landings--color--dark-turquoise);
	cursor: pointer;
	user-select: none;
}

@media screen and (min-width: 1024px) {
	.root {
		padding-top: 50px;
		padding-bottom: 150px;
	}

	.title {
		margin-bottom: 40px;
	}

	.question-root {
		padding: 12px 0;
	}

	.question {
		font-size: 24px;
		line-height: 1.1em;
	}

	.answer {
		font-size: 15px;
		line-height: 1.4em;
	}
}

@media screen and (min-width: 1440px) {
	.root {
		padding-top: 100px;
		padding-bottom: 200px;
	}

	.question-root {
		padding: 26px 0;
	}

	.question {
		font-size: 36px;
		line-height: 1.2em;
	}

	.answer {
		font-size: 24px;
	}
}
