.root-bg {
	background-color: var(--vkws_landings--bg_body);
	position: relative;
}

.section {
	display: grid;
	grid-template-columns: 1fr;
	align-items: flex-start;
	padding-top: 10px;
	padding-bottom: 60px;
	position: relative;
}

.main-title {
	margin: 0;
	padding-bottom: 32px;
	color: var(--vkws_landing--variant-card-title--color);
	font-family: var(--vkws_landings--default-font--family);
	font-size: 25px;
	font-style: normal;
	font-weight: 500;
	line-height: 136%;
}

.cards {
	display: flex;
	flex-direction: column;
	gap: 20px;
}

.card {
	overflow: hidden;
	background-color: var(--vkws_landing--variant-card--bg);
	width: 100%;
	height: 392px;
	border-radius: 10px;
	padding: 22px 18px 36px;
	position: relative;
	display: flex;
	flex-direction: column;
}

.card-head {
	margin-bottom: 8px;
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 16px;
}

.title {
	color: var(--vkws_landings--color--white);
	font-family: var(--vkws_landings--default-font--family);
	font-size: 16px;
	font-style: normal;
	font-weight: 500;
	line-height: 120%;
}

.subtitle {
	color: var(--vkws_landing--promo-desc--color);
	font-family: var(--vkws_landings--default-font--family);
	font-size: 10px;
	font-style: normal;
	font-weight: 400;
	line-height: 140%;
	margin-bottom: 20px;
	max-width: 230px;
}

.icon {
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 16px;
	padding: 11px;
	background: var(--vkws_landing--services-card-icon--bg);
}

.icon svg {
	width: 24px;
	height: 24px;
}

.desc {
	color: var(--vkws_landing--variant-card-desc--color);
	font-family: var(--vkws_landings--default-font--family);
	font-size: 12px;
	font-style: normal;
	display: flex;
	flex-direction: column;
	gap: 8px;
	font-weight: 500;
	line-height: 130%;
}

.desc-item {
	display: flex;
	align-items: flex-start;
	gap: 8px;
	align-self: stretch;
}

.desc-item svg {
	min-width: 12px;
}

.button-wrapper {
	margin-top: auto;
	margin-bottom: 0;
}

.button {
	height: 50px;
	width: 100%;
	border-radius: 6px;
	font-size: 16px;
	font-weight: 500;
}

.white-button {
	background-color: var(--vkws_landings--color--white);
	color: var(--vkws_landings--color--black);
}

.white-button:hover, .white-button:focus, .white-button:active {
	background-color: var(--vkws_landing--variant-card-button--bg-hover);
	color: var(--vkws_landings--color--black);
}

@media screen and (min-width: 1024px) {
	.section {
		padding-top: 10px;
		padding-bottom: 30px;
		width: 884px;
	}

	.main-title {
		padding-bottom: 55px;
		font-size: 36px;
		line-height: 120%;
	}

	.cards {
		flex-direction: row;
		gap: 10px;
	}

	.card {
		width: 50%;
		height: 500px;
		padding: 26px 30px 40px;
		border-radius: 14px
	}

	.card-head {
		margin-bottom: 12px;
		gap: 16px;
	}

	.icon {
		display: flex;
		justify-content: center;
		align-items: center;
		border-radius: 16px;
		padding: 16px;
		background: var(--vkws_landing--services-card-icon--bg);
	}

	.icon svg {
		width: 28px;
		height: 28px;
	}

	.subtitle {
		font-size: 12px;
		margin-bottom: 17px;
	}

	.button-wrapper {
		margin-top: auto;
	}

	.title {
		font-size: 24px;
		line-height: 130%;
	}

	.desc {
		gap: 8px;
		font-size: 16px;
		font-weight: 400;
		line-height: 140%;
	}

	.desc-item {
		gap: 8px;
	}

	.desc-item svg {
		min-width: 16px;
	}

	.button {
		height: 40px;
		width: 100%;
		border-radius: 6px;
		font-size: 16px;
	}
}

@media screen and (min-width: 1440px) {
	.section {
		padding-top: 30px;
		padding-bottom: 10px;
		width: 1240px;
	}

	.main-title {
		font-size: 54px;
		padding-bottom: 80px;
		line-height: 110%;
	}

	.cards {
		gap: 12px;
	}

	.card {
		border-radius: 24px;
		width: 50%;
		height: 611px;
		padding: 36px;
	}

	.card-head {
		margin-bottom: 24px;
		gap: 26px;
	}

	.title {
		font-size: 28px;
		line-height: 130%;
	}

	.subtitle {
		font-size: 20px;
		margin-bottom: 48px;
		max-width: 400px;
	}

	.desc {
		gap: 12px;
		font-size: 20px;
		font-style: normal;
		font-weight: 400;
		line-height: 135%;
	}

	.desc-item {
		gap: 12px;
	}

	.desc-item svg {
		min-width: 21px;
	}

	.button {
		height: 66px;
		width: 100%;
		border-radius: 12px;
		font-size: 24px;
	}
}
