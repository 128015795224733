.root {
	width: 100%;
	display: grid;
	grid-template-columns: 1fr auto;
	grid-template-rows: auto auto;
	grid-template-areas:
    ". ."
    "answer answer";

	padding-top: 16px;
	padding-bottom: 16px;
}

.control {
	margin: 1px 0 0 20px;
	flex-shrink: 0;
}

.question {
	font-style: normal;
	font-weight: 600;
	font-size: 16px;
	line-height: 24px;
	color: var(--vkws_landing--faq_quesion--color);
	cursor: pointer;
	user-select: none;
}

.answer-wrapper {
	grid-area: answer;
	max-height: 0;
	overflow: hidden;
	transition: max-height var(--vkws_landings--transition--duration) var(--vkws_landings--transition--timing-function);
}

.answer {
	margin-top: 0;
	margin-bottom: 0;

	padding-top: 12px;
}

.display_visible {
	max-height: 600px;
}

@media screen and (min-width: 1024px) {
	.root {
		padding-top: 28px;
		padding-bottom: 28px;
	}

	.root.separator {
		border-bottom: 1px solid var(--vkws_landing--faq_question--border-color);

		padding-top: 40px;
		padding-bottom: 40px;
	}

	.control {
		margin-top: auto;
		margin-bottom: auto;
	}

	.question {
		font-weight: 500;
		font-size: 36px;
		line-height: 44px;
	}

	.answer {
		opacity: .7;
		padding-top: 24px;
	}
}

@media screen and (min-width: 1440px) {
	.root {
		gap: 0 60px;
		grid-template-areas:
		". ."
		"answer .";
	}

	.question {
		font-weight: 400;
	}
}
