.required-wrapper {
	position: relative;
	flex-grow: 1;
}

.root {
	text-align: left;
	border-radius: 8px;
	transition-property: border-color, background-color, opacity;
	transition-duration: 0.15s;
	transition-timing-function: ease-in-out;

	font-family: inherit;
	box-sizing: border-box;
	display: block;

	background: var(--vkws_landing--textarea--bg);
	border: var(--vkws_landing--textarea--border);

	font-style: normal;
	font-weight: 400;
	font-size: 18px;
	line-height: 28px;

	color: var(--vkws_landing--textarea--text-color);

	padding: 10px 20px;
}
.root.appearance_blue {
	background: var(--vkws_landing--input_blue--bg);
	border: var(--vkws_landing--input_blue--border);
	color: var(--vkws_landings--color--white);
}
.root.appearance_blue:hover {
	background: var(--vkws_landing--input_blue--bg_hover);
}
.root.appearance_blue:focus,
.root.appearance_blue:active {
	border: var(--vkws_landing--input_blue--border_active);
	background: inherit;
	outline: none;
}

.root:hover {
	background: var(--vkws_landing--textarea--bg_hover);
	border: var(--vkws_landing--textarea--border_hover);
}

.root:focus,
.root:active {
	border: var(--vkws_landing--textarea--border_focus);
	background: var(--vkws_landing--textarea--bg_focus);
	outline: none;
}


.state_invalid,
.root[aria-invalid='true'] {
	border-color: #FF8080;
}

.state_invalid:hover,
.root[aria-invalid='true']:hover {
	border-color: #FF8080;
}

.state_invalid:focus,
.state_invalid:active,
.root[aria-invalid='true']:focus ,
.root[aria-invalid='true']:active {
	border-color: #FF8080;
}

.appearance_blue .root[aria-invalid='true'] {
	border-color: var(--vkws_landing--input_blue--border_invalid_color);
}

.appearance_blue .root[aria-invalid='true']:hover {
	border-color: var(--vkws_landing--input_blue--border_invalid_color);
}

.appearance_blue .root[aria-invalid='true']:focus ,
.appearance_blue .root[aria-invalid='true']:active {
	border-color: var(--vkws_landing--input_blue--border_invalid_color);
}

.required {
	position: absolute;
	top: -10px;
	right: -10px;
	font-size: 20px;
	color: var(--vkws_landing--input--required-star--color);
}

.field-error {
	position: absolute;
	bottom: -24px;
	display: block;
	font-weight: 400;
	font-size: 12px;
	line-height: 24px;
	color: #ff8080;
	white-space: nowrap;
}

.appearance_blue .field-error {
	color: var(--vkws_landing--input--required-star-light--color);
}

.appearance_blue .required {
	color: var(--vkws_landing--required-blue--color);
	opacity: 0.5;
}
