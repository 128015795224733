.root {
	display: none;
}

@media screen and (min-width: 1024px) {
	.root {
		width: 100%;
		height: 436px;
		border-radius: 14px;
		display: flex;
		flex-direction: column;
	}

	.head {
		padding: 24px 36px 0;
		color: var(--vkws_landing--title--color);
		font-size: 18px;
		font-style: normal;
		font-weight: 400;
		line-height: 130%;
	}

	.img-wrapper {
		position: relative;
		margin-left: auto;
		margin-top: auto;
		width: calc(100% - 36px);
		height: auto;
	}

	.img {
		width: auto;
		height: 320px;
		position: absolute;
		bottom: 0;
		right: 0;
	}
}

@media screen and (min-width: 1440px) {
	.root {
		height: 612px;
	}

	.head {
		padding: 33px 51px 0;
		color: var(--vkws_landing--title--color);
		font-size: 24px;
		font-style: normal;
		font-weight: 400;
		line-height: 130%;
	}

	.img-wrapper {
		position: relative;
		margin-left: auto;
		margin-top: auto;
		width: calc(100% - 51px);
		height: auto;
	}

	.img {
		width: auto;
		height: 449px;
		position: absolute;
		bottom: 0;
		right: 0;
	}
}
