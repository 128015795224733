.root-bg {
	background-color: var(--vkws_landings--color--black);
}

.root {
	padding-top: 10px;
	padding-bottom: 50px;
}

.title {
	margin-bottom: 32px;
}

.desc {
	color: var(--vkws_landing--promo-desc--color);
	font-size: 15px;
	line-height: 21px;
	margin: 0;
}

.content-title {
	color: var(--vkws_landings--color--white);
	font-size: 20px;
	font-style: normal;
	font-weight: 500;
	line-height: 120%;
	margin: 0;
	padding: 0;
}

.content-text {
	color: var(--vkws_landings--color--desc-grey);
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 140%;
	margin: 0;
	padding: 0;
}

@media screen and (min-width: 1024px) {
	.root {
		flex-direction: column;
		padding-top: 10px;
		padding-bottom: 100px;
	}

	.title {
		margin-bottom: 40px;
	}

	.desc {
		display: none;
	}

	.content-title {
		font-size: 20px;
		line-height: 114%;
	}

	.content-text {
		font-size: 13px;
		line-height: 140%;
	}
}

@media screen and (min-width: 1440px) {
	.root {
		padding-bottom: 150px;
		padding-top: 10px;
	}

	.title {
		margin-bottom: 80px;
	}

	.content-title {
		font-size: 28px;
		line-height: 32px;
	}

	.content-text {
		font-size: 18px;
	}
}
