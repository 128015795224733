.root {
	display: grid;
	background: var(--vkws_landings--color--white);
	border-radius: 13px;
	padding: 24px 24px 148px;
	overflow: hidden;
	margin-top: 0;
}

.img-wrapper {
	position: absolute;
	height: auto;
	width: 292px;
	left: auto;
	right: -38px;
	top: auto;
	bottom: -156px;
}

.title {
	color: var(--vkws_landings--color--black);
	font-size: 20px;
	font-style: normal;
	font-weight: 500;
	line-height: 120%;
}

.accent {
	font-weight: 600;
	color: var(--vkws_landings--color--main_blue);
}

.body {
	position: relative;
	display: grid;
	grid-template-columns: 1fr;
}

.picture {
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	align-items: center;
	position: relative;
}

.date {
	margin-top: 10px;
	margin-bottom: 10px;
	text-align: left;
	color: var(--vkws_landings--color--main_blue);
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 130%;
}

.button {
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 16px;
	border-radius: 7px;
	height: 38px;
	width: 100%;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
}

.desc {
	margin-top: 30px;
}

.desc-title {
	font-size: 12px;
	font-weight: 500;
	text-align: left;
	color: var(--vkws_landings--color--promo-grey);
	margin-bottom: 8px;
	line-height: 140%;
}

.items {
	display: flex;
	flex-direction: column;
	gap: 4px;
}

.item {
	font-size: 12px;
	font-weight: 400;
	gap: 6px;
	color: var(--vkws_landings--color--promo-grey);
	display: flex;
	line-height: 135%;
}

.item svg {
	width: 16px;
	height: 16px;
}

.item-bold {
	font-weight: 600;
}

.disclaimer {
	font-weight: 500;
	text-align: left;
	margin-top: 20px;
	color: var(--vkws_landings--color--promo-grey);
	font-size: 12px;
	line-height: normal;
}

.link {
	color: var(--vkws_landings--color--main_blue);
}

.info {
	width: 254px;
}

@media screen and (min-width: 1024px) {
	.root {
		padding: 30px;
		margin-top: 60px;
	}

	.title {
		font-size: 36px;
		line-height: 110%;

	}

	.date {
		margin-top: 16px;
		margin-bottom: 16px;
		font-size: 14px;
		font-weight: 500;
		line-height: 18px;
	}

	.body {
		grid-template-columns: 1fr 1fr;
	}

	.img-wrapper {
		width: 300px;
		left: auto;
		right: -30px;
		top: auto;
		bottom: -35px;
	}

	.button {
		font-size: 16px;
		border-radius: 7px;
		height: 38px;
		width: 250px;
	}

	.desc-title {
		line-height: 18px;
		margin-bottom: 5px;
	}

	.disclaimer {
		font-size: 10px;
		line-height: 12px;
		margin-top: 15px;
	}

	.items {
		gap: 3px;
	}

	.item {
		line-height: 18px;
		gap: 12px;
	}

	.item svg {
		width: 18px;
		height: 18px;
	}

	.info {
		width: auto;
	}
}

@media screen and (min-width: 1440px) {
	.root {
		border-radius: 20px;
		padding: 36px;
		margin-top: 130px;
	}

	.img-wrapper {
		width: 431px;
		left: auto;
		right: -36px;
		top: auto;
		bottom: -40px;
	}

	.title {
		font-size: 54px;
	}

	.date {
		margin-top: 20px;
		margin-bottom: 24px;
		font-size: 28px;
	}

	.button {
		font-size: 22px;
		height: 58px;
		width: 357px;
		border-radius: 10px;
	}

	.desc {
		margin-top: 43px;
	}

	.desc-title {
		font-size: 15px;
		line-height: 21px;
		margin-bottom: 8px;
	}

	.disclaimer {
		font-size: 12px;
		font-weight: 500;
		line-height: 15px;
		text-align: left;
		margin-top: 20px;
	}

	.items {
		gap: 4px;
	}

	.item {
		font-size: 15px;
		font-weight: 400;
		line-height: 20px;
		gap: 12px;
	}

	.item svg {
		width: 20px;
		height: 20px;
	}
}
