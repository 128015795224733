.root {
	flex-direction: column;
	align-items: center;
	padding-top: 25px;
	padding-bottom: 50px;
	gap: 35px;
	position: relative;
}

.media {
	position: absolute;
    width: 400px;
    height: 200px;
    top: -120px;
    right: -700px;
    border-radius: 70% / 70%;
    background-color: rgba(0, 178, 255, 0.7);
    box-shadow: 0 0 401px 400px rgba(0, 178, 255, 0.7);
}

.img-wrapper {
	position: relative;
	width: 251px;
	height: 262px;
}

.img {
	width: 100%;
	height: auto;
}

.content {
	align-self: stretch;
}

.title {
	font-weight: 500;
	margin: 0;
	color: var(--vkws_landing--promo-title--color);
}

.desc {
	margin-bottom: 0;
	margin-top: 23px;
	color: var(--vkws_landing--promo-desc--color);
	line-height: 1.4em;
	opacity: 1;
}

.buttons {
	width: 100%;
	margin-top: 29px;
	display: flex;
	flex-wrap: wrap;
	gap: 10px;
	justify-content: space-between;
	align-items: center;
}

.button {
	font-weight: 500;
	padding: 0 15px;
	width: 100%;
}

.wrap-button {
	width: 100%;
}

.button-outline {
	border: 2px solid var(--vkws_landings--color--main_blue);
	color: var(--vkws_landings--color--main_blue);
	background-color: inherit;
}

.button-outline:hover {
	background-color: var(--vkws_landings--color--main_blue);
	color: var(--vkws_landings--color--white);
}

@media screen and (min-width: 1024px) {
	.root {
		flex-direction: row-reverse;
		align-items: stretch;
		justify-content: flex-end;
		gap: 97px;
		padding-top: 90px;
		position: relative;
	}

	.content {
		flex-basis: 460px;
		flex-shrink: 0;
		z-index: 0;
	}

	.img-wrapper {
		width: 275px;
		height: 287px;
	}

	.title {
		font-size: 40px;
		line-height: 44px;
	}

	.desc {
		margin-top: 20px;
		font-size: var(--vkws_landings--font_text--font_size);
	}

	.buttons {
		width: unset;
		justify-content: flex-start;
		gap: 22px;
	}

	.button {
		padding: 0 24px;
	}

	.wrap-button {
		width: unset;
	}
}

@media screen and (min-width: 1440px) {
	.root {
		gap: 60px;
		padding-top: 160px;
	}

	.content {
		flex-basis: 770px;
	}

	.media {

	}

	.img-wrapper {
		width: 383px;
		height: 399px;
	}

	.title {
		width: unset;
		font-size: 64px;
		line-height: 70px;
	}

	.desc {
		margin-top: 28px;
		font-size: 24px;
		line-height: 34px;
		width: 75%;
	}

	.buttons {
		margin-top: 40px;
		gap: 24px;
	}

	.button {
		padding: 0 36px;
	}
}

@media screen and (min-width: 1680px) {
	.buttons {
		margin-top: 38px;
	}
}
