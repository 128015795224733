.root {
	margin-top: 40px;
	display: flex;
	gap: 12px;
	flex-direction: column;
}

.goal {
	color: var(--vkws_landings--goal-text--color);
	font-family: "VK Sans Display", "Proxima Nova", Arial, Helvetica, "Helvetica Neue", sans-serif;
	font-size: 12px;
	font-weight: 500;
	line-height: 14px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	gap: 16px;
	flex-direction: row;
	width: 100%;
	height: min-content;
	padding: 16px 16px 16px 20px;
	border-radius: 12px;
	background-color: var(--vkws_landings--color--black);
}

.goal a {
	cursor: pointer;
	color: var(--vkws_landings--color--main_blue);
}

.goal a:hover {
	text-decoration-line: underline;
}

.icon-wrapper {
	display: flex;
	justify-content: center;
	align-items: center;
	min-width: 46px;
	min-height: 46px;
	border-radius: 10px;
	background-color: var(--vkws_landings--goal-icon--bg);
}

.icon {
	width: 28px;
	height: 28px;
}

@media screen and (min-width: 1024px) {
	.root {
		margin-top: 40px;
		width: 100%;
		gap: 10px;
		flex-direction: row;
		flex: 1 1 100%;
	}

	.goal {
		font-size: 13px;
		line-height: 24px;
		width: 50%;
		height: min-content;
		padding: 16px 16px 16px 26px;
		gap: 16px;
		border-radius: 16px;
	}

	.icon-wrapper {
		min-width: 60px;
		min-height: 60px;
		border-radius: 16px;
	}
}

@media screen and (min-width: 1440px) {
	.root {
		margin-top: 50px;
		gap: 12px;
	}

	.goal {
		font-size: 20px;
		line-height: 32px;
		border-radius: 20px;
	}
}
