:local .root {
	--vkws_landings--company-container--bg--light: var(--vkws_landings--color--white);
	--vkws_landings--company-container--bg--dark: var(--vkws_landings--color--black);
	--vkws_landings--company-card--bg--dark: #212121;
	--vkws_landings--company-card--bg--light: #E3E8F2;
	--vkws_landings--company-title--color--dark: #ffffff;
	--vkws_landings--company-title--color--light: #212121;
}

.root {
	padding-bottom: 50px;
	background-color: var(--vkws_landings--company-container--bg--dark);
}

.root-light {
	background-color: var(--vkws_landings--company-container--bg--light);
}

.root-fm {
	position: relative;
}

.section {
	display: grid;
	grid-template-columns: 1fr;
	padding-top: 10px !important;
	padding-bottom: 0 !important;
}

.carousel {
	position: relative;
	height: 71px;
}

.cards {
	position: absolute;
	width: max-content;
	display: flex;
	flex-direction: row;
	gap: 8px;
}

.card {
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 14px;
	min-width: 146px;
	height: 71px;
}

.card-light {
	background: var(--vkws_landings--company-card--bg--light);
}

.card-dark {
	background: var(--vkws_landings--company-card--bg--dark);
}

.title-light {
	color: var(--vkws_landings--company-title--color--light);
}

.title-dark {
	color: var(--vkws_landings--company-title--color--dark);
}

.title {
	font-family: "VK Sans Display", "Proxima Nova", Arial, Helvetica, "Helvetica Neue", sans-serif;
	font-style: normal;
	font-weight: 500;
	font-size: 26px;
	line-height: 29px;
	margin-bottom: 22px;
	margin-top: 0;
}

.logo {
	scale: 0.8;
}

@media screen and (min-width: 1024px) {
	.root {
		padding-bottom: 103px;
	}

	.section {
		padding-top: 30px !important;
		padding-bottom: 0 !important;
	}

	.carousel {
		height: 71px;
	}

	.cards {
		gap: 8px;
	}

	.card {
		border-radius: 14px;
		min-width: 146px;
		height: 71px;
	}

	.title {
		font-size: 36px;
		line-height: 110%;
		margin-bottom: 58px;
		margin-top: 0;
	}

	.logo {
		scale: 0.8;
	}
}

@media screen and (min-width: 1440px) {
	.root {
		padding-bottom: 145px;
	}

	.section {
		padding-top: 60px !important;
		padding-bottom: 0 !important;
	}

	.carousel {
		height: 100px;
	}

	.cards {
		gap: 11px;
	}

	.card {
		border-radius: 20px;
		min-width: 206px;
		height: 100px;
	}

	.title {
		font-size: 52px;
		line-height: 110%;
		margin-bottom: 80px;
		margin-top: 0;
	}

	.logo {
		scale: 1;
	}
}
