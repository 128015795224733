.root {
	margin: 0;
	font-size: 26px;
	font-weight: 500;
	line-height: 29px;
}

@media screen and (min-width: 1024px) {
	.root {
		font-size: 36px;
		line-height: 40px;
	}
}

@media screen and (min-width: 1440px) {
	.root {
		font-size: 54px;
		line-height: 59px;
	}
}

@media screen and (min-width: 1680px) {
	.root-1680 {
		font-size: 62px;
		line-height: 68px;
	}
}
